var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-5"},[_c('basic-form',{attrs:{"title":_vm.title,"isLoading":_vm.loading},on:{"save":_vm.save,"update:isLoading":function($event){_vm.loading=$event},"update:is-loading":function($event){_vm.loading=$event}}},[_c('template',{slot:"fields"},[_c('div',{staticClass:"flex flex-col md:flex-row justify-center"},[_c('div',{staticClass:"w-full md:w-1/2 mr-3"},[_c('t-input-group',{staticClass:"w-full md:ml-2 md:mr-2 my-4",attrs:{"feedback":_vm.showValidation && _vm.errors.first('type')
                ? _vm.errors.first('type')
                : '',"variant":_vm.showValidation && _vm.errors.first('type') ? 'danger' : '',"label":'Tipo de título'}},[_c('t-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"data-vv-validate-on":"input","name":"type","id":"type","placeholder":"Seleccione una opción","disabled":_vm.loading,"options":[
                { index: 1, name: 'Academia' },
                { index: 2, name: 'Entrenador' },
                { index: 3, name: 'Jugador' }
              ],"textAttribute":"name","valueAttribute":"index","variant":_vm.showValidation && _vm.errors.first('type') ? 'danger' : ''},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}})],1),_c('t-input-group',{staticClass:"w-full md:ml-2 md:mr-2 my-4",attrs:{"feedback":_vm.showValidation && _vm.errors.first('name')
                ? _vm.errors.first('name')
                : '',"variant":_vm.showValidation && _vm.errors.first('name') ? 'danger' : '',"label":'Nombre'}},[_c('t-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|max:200'),expression:"'required|max:200'"}],attrs:{"data-vv-validate-on":"input","name":"name","placeholder":"Ingrese el nombre","autocomplete":"off","disabled":_vm.loading,"type":"text","variant":_vm.showValidation && _vm.errors.first('name') ? 'danger' : ''},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('t-input-group',{staticClass:"w-full md:ml-2 md:mr-2 my-4",attrs:{"feedback":_vm.showValidation && _vm.errors.first('description')
                ? _vm.errors.first('description')
                : '',"variant":_vm.showValidation && _vm.errors.first('description') ? 'danger' : '',"label":'Descripción del título'}},[_c('t-textarea',{directives:[{name:"validate",rawName:"v-validate",value:('required|max:200'),expression:"'required|max:200'"}],attrs:{"data-vv-validate-on":"input","name":"description","placeholder":"¿A quién se le premia con este título?","autocomplete":"off","disabled":_vm.loading,"type":"text","variant":_vm.showValidation && _vm.errors.first('description') ? 'danger' : '',"rows":"3"},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}})],1)],1),_c('div',{staticClass:"w-full md:w-1/2 mx-1 relative"},[_c('div',{staticClass:"flex flex-col items-center justify-around mt-2 mb-10"},[_c('t-input-group',{staticClass:"w-full md:w-1/2 md:mr-0 md:pr-2",attrs:{"feedback":_vm.showValidation && _vm.errors.first('url_picture')
                  ? _vm.errors.first('url_picture')
                  : '',"variant":_vm.showValidation && _vm.errors.first('url_picture') ? 'danger' : ''}},[_c('label',{staticClass:"block text-left font-bold"},[_vm._v("Foto del título")]),_c('t-upload-file',{attrs:{"disabled":_vm.loading,"file":_vm.form.url_picture,"externalUrl":typeof _vm.form.url_picture === 'string' ? _vm.form.url_picture : '',"backgroundColor":"bg-white","placeholder":"Selecciona una imagen","height":"125px","accept":"image/*"},on:{"update:file":function($event){return _vm.$set(_vm.form, "url_picture", $event)}}})],1)],1),_c('div',{staticClass:"flex justify-start md:ml-2 md:mr-2 my-4"},[_c('p',[_vm._v("Mostrar en la app")]),_c('t-toggle',{staticClass:"mx-3",attrs:{"disabled":_vm.loading},on:{"click":function($event){return _vm.toggleVisibility(!_vm.visible)}},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}}),_c('p',[_vm._v("Sí")])],1),(_vm.deletable)?_c('div',{staticClass:"absolute bottom-0 w-full flex justify-center"},[_c('t-button',{attrs:{"type":"button","variant":"error"},on:{"click":_vm.deleteElement}},[_vm._v("Eliminar título")])],1):_vm._e()])])])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }