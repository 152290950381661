<template>
  <div class="px-5">
    <Form
      title="Crear título"
      ref="titleForm"
      :loading.sync="loading"
      @save="save"
      @toggleVisibility="toggleVisibility"
    />
  </div>
</template>

<script>
const notify = () => import("@/utils/notify.js")
import Form from "./Form.vue"
export default {
  components: {
    Form
  },
  data() {
    return {
      loading: false,
      visible: false
    }
  },
  methods: {
    toggleVisibility(visible) {
      this.visible = visible
    },
    save() {
      let form = this.$refs.titleForm.form
      this.loading = true

      this.$store
        .dispatch("global/create", {
          payload: {
            type: form.type,
            name: form.name,
            description: form.description,
            url_picture: form.url_picture,
            active: !this.visible ? 0 : 1
          },
          route: "/master/title/save",
          module: "title"
        })
        .then((response) => {
          notify().then(({ notification }) => {
            notification("master", response.code, this.$snotify)
          })
          this.loading = false

          if ([200, 201].includes(response.code)) {
            this.$router.push("/titulos")
          }
        })
        .catch((error) => {
          this.loading = false
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    }
  },
  created() {}
}
</script>
