<template>
  <div class="px-5">
    <basic-form :title="title" @save="save" :isLoading.sync="loading">
      <template slot="fields">
        <div class="flex flex-col md:flex-row justify-center">
          <div class="w-full md:w-1/2 mr-3">
            <t-input-group
              :feedback="
                showValidation && errors.first('type')
                  ? errors.first('type')
                  : ''
              "
              :variant="showValidation && errors.first('type') ? 'danger' : ''"
              class="w-full md:ml-2 md:mr-2 my-4"
              :label="'Tipo de título'"
            >
              <t-select
                v-validate="'required'"
                data-vv-validate-on="input"
                v-model="form.type"
                name="type"
                id="type"
                placeholder="Seleccione una opción"
                :disabled="loading"
                :options="[
                  { index: 1, name: 'Academia' },
                  { index: 2, name: 'Entrenador' },
                  { index: 3, name: 'Jugador' }
                ]"
                textAttribute="name"
                valueAttribute="index"
                :variant="
                  showValidation && errors.first('type') ? 'danger' : ''
                "
              ></t-select>
            </t-input-group>

            <t-input-group
              :feedback="
                showValidation && errors.first('name')
                  ? errors.first('name')
                  : ''
              "
              :variant="showValidation && errors.first('name') ? 'danger' : ''"
              class="w-full md:ml-2 md:mr-2 my-4"
              :label="'Nombre'"
            >
              <t-input
                v-validate="'required|max:200'"
                data-vv-validate-on="input"
                v-model="form.name"
                name="name"
                placeholder="Ingrese el nombre"
                autocomplete="off"
                :disabled="loading"
                type="text"
                :variant="
                  showValidation && errors.first('name') ? 'danger' : ''
                "
              />
            </t-input-group>

            <t-input-group
              :feedback="
                showValidation && errors.first('description')
                  ? errors.first('description')
                  : ''
              "
              :variant="
                showValidation && errors.first('description') ? 'danger' : ''
              "
              class="w-full md:ml-2 md:mr-2 my-4"
              :label="'Descripción del título'"
            >
              <t-textarea
                v-validate="'required|max:200'"
                data-vv-validate-on="input"
                v-model="form.description"
                name="description"
                placeholder="¿A quién se le premia con este título?"
                autocomplete="off"
                :disabled="loading"
                type="text"
                :variant="
                  showValidation && errors.first('description') ? 'danger' : ''
                "
                rows="3"
              />
            </t-input-group>
          </div>

          <div class="w-full md:w-1/2 mx-1 relative">
            <div class="flex flex-col items-center justify-around mt-2 mb-10">
              <t-input-group
                :feedback="
                  showValidation && errors.first('url_picture')
                    ? errors.first('url_picture')
                    : ''
                "
                :variant="
                  showValidation && errors.first('url_picture') ? 'danger' : ''
                "
                class="w-full md:w-1/2 md:mr-0 md:pr-2"
              >
                <label class="block text-left font-bold">Foto del título</label>
                <t-upload-file
                  :disabled="loading"
                  :file.sync="form.url_picture"
                  :externalUrl="
                    typeof form.url_picture === 'string' ? form.url_picture : ''
                  "
                  backgroundColor="bg-white"
                  placeholder="Selecciona una imagen"
                  height="125px"
                  accept="image/*"
                />
              </t-input-group>
            </div>

            <div class="flex justify-start md:ml-2 md:mr-2 my-4">
              <p>Mostrar en la app</p>
              <t-toggle
                class="mx-3"
                v-model="visible"
                @click="toggleVisibility(!visible)"
                :disabled="loading"
              />
              <p>Sí</p>
            </div>

            <div
              class="absolute bottom-0 w-full flex justify-center"
              v-if="deletable"
            >
              <t-button type="button" variant="error" @click="deleteElement"
                >Eliminar título</t-button
              >
            </div>
          </div>
        </div>
      </template>
    </basic-form>
  </div>
</template>

<script>
const notify = () => import("@/utils/notify.js")
export default {
  components: {},
  props: {
    title: {
      type: String
    },
    deletable: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      form: {
        type: null,
        name: null,
        description: null,
        url_picture: null
      },
      showValidation: false,
      visible: false
    }
  },
  computed: {
    user() {
      return this.$store.getters["auth/getUser"]
    }
  },
  methods: {
    toggleVisibility(visible) {
      this.$emit("toggleVisibility", visible)
    },
    save() {
      this.$validator.validateAll().then((isValid) => {
        this.showValidation = true
        if (isValid) {
          if (!this.form.url_picture) {
            return this.$snotify.error(
              "La foto del título es obligatoria",
              "Error",
              {
                timeout: 5000,
                showProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                titleMaxLength: 40,
                bodyMaxLength: 100,
                position: "rightBottom"
              }
            )
          }
          this.$emit("save")
        }
      })
    },
    deleteElement() {
      this.$dialog
        .confirm({
          title: "¿Estás seguro?",
          text: `Esta acción eliminará permanentemente el título.`,
          icon: "question",
          escToClose: true,
          cancelButtonText: "Cancelar",
          okButtonText: "Ok"
        })
        .then((result) => {
          if (result.isOk) {
            this.$store
              .dispatch("global/changeStatus", {
                payload: {
                  id: this.form.id,
                  status: 2
                },
                route: "/master/title",
                module: "title"
              })
              .then((response) => {
                notify().then(({ notification }) => {
                  notification(
                    "master-status-delete",
                    response.code,
                    this.$snotify
                  )
                })
                if (response.code === 200) this.$router.push("/titulos")
              })
              .catch((error) => {
                notify().then(({ notification }) => {
                  notification("error", error.code, this.$snotify)
                })
              })
          }
        })
    }
  },
  created() {}
}
</script>
